<template>
  <div class="row justify-content-between">
    <div class="col-8 col-auto me-auto">
      <div
        style="float: left"
        class="title"
        v-for="c in post.Categories"
        :key="c.ID"
      >
        <router-link :to="'/category/' + c.Name"> {{ c.Name }} </router-link>
        |&nbsp;
      </div>
      <ul class="tags">
        <li v-for="t in post.Tags" :key="t.ID">
          <router-link :to="'/tag/' + t.Name">{{ t.Name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="col-auto">
      <router-link 
      style="padding-right:17px;"
      v-if="isAuth" :to="'/admin/edit/' + post.ID"
      title="edit"
        ><i class="far fa-edit fa-w-14 fa-lg" aria-hidden="true"></i>
      </router-link>
      <a v-if="isAuth" @click.prevent="delpost(post.ID)" href="#"
      title="delete"
        ><i class="far fa-trash-alt fa-lg" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "../ext1";

@Component({
  // components: { multiselect: multiselect }  //multiselect:Multiselect
  name: "PostFooter",
})
export default class footermd extends Vue {
  name = "post-footer";
  @Prop()
  post: Object; //= p(Object)

  delpost(ID: number) {
    //console.log("deleting"+ID);
    if (confirm("Really delete?")) {
      //this.$emit('delete', this.post);
      this.db.bHub.deletePost(ID).then(() => {
        this.$emit("delete", this.post);
        // this.getData();
        // console.log("getin data");
      });
    }
  }
}
</script>
