import de from "../components/de";
import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";


export interface valconfig {
    errorBagName: string,
    fieldsBagName: string,
    delay:number,
    locale:string,
    dictionary:object,
    strict:boolean,
    enableAutoClasses:boolean, 
    classNames:object

  }
const config : valconfig = {
    errorBagName: "errors", // change if property conflicts.
    fieldsBagName: "vfields",
    delay: 0,
    locale: "de",
    dictionary: { de: { messages: de } }, //null, //
    strict: true,
    enableAutoClasses: false,
    classNames: {
      touched: "touched", // the control has been blurred
      untouched: "untouched", // the control hasn't been blurred
      valid: "valid", // model is valid
      invalid: "err", // model is invalid
      pristine: "pristine", // control has not been interacted with
      dirty: "dirty" // control has been interacted with
    }
  };

import VVue from "vue";
VVue.use(VeeValidate, config);
  //change lang here
//Validator.locale = 'de';
//Validator.setLocale('de');
  export default config;