import { Component, Vue } from "vue-property-decorator";
import { RouterOptions, Location, RouteConfig, Route } from "vue-router";
import VueRouter from "vue-router";
//import './vendor' //bootstrap
//require('./main.scss'); //global css

/*
  For components that will be used in html (such as navbar),
  all you need to do is import the file somewhere in your code,
  they are automatically registered when the file is loaded.
  However, if you import the class (ex: import { Navbar } from './navbar'),
  you will have to call new Navbar() somewhere as well. You would want
  to do that if you are defining a components{} object in the @Component
  options parameter. 
*/
declare var require: any;

const separatets = () => import("../views/separatets.vue");
const about = () => import("../views/about.vue");
import home from "../views/home.vue";

import post from "../views/mdpost.vue";
const test = () => import("../views/test.vue");
const admin = () => import("../views/admin.vue");
const contact = () => import("../views/contact.vue");
const slides = () => import("../views/slides.vue");
//var test = home

const router = new VueRouter({
  mode: "history",
  base: "", //subdomain
  routes: [
    {
      path: "/",
      component: home,
      meta: {
        title: "information rocks",
        metaTags: [
          {
            name: "description",
            content: "blog info and more"
          },
          {
            property: "og:description",
            content: "blog info and more"
          }
        ]
      }
    },
    { path: "/login", component: home },
    { path: "/slides/", component: slides },
    { path: "/slides/:id", component: slides },
    { path: "/page/:nr", component: home },
    { path: "/search/:term", component: home },
    { path: "/search/:term/page/:nr", component: home },
    { path: "/category/:cname", component: home },
    { path: "/category/:cname/page/:nr", component: home },
    { path: "/tag/:tname", component: home },
    { path: "/tag/:tname/page/:nr", component: home },
    { path: "/post/:pname", component: post },
    { path: "/author/:aname", component: home },
    { path: "/author/:aname/page/:nr", component: home },
    { path: "/contact", component: contact },
    //test guys
    { path: "/ts", component: separatets },
    // { path: '/ts/:id(\\d+)', component: separatets },
    { path: "/about", component: about },
    { path: "/test/:tstparam", component: test },
    { path: "/test", component: test },

    //test guys
    { path: "/admin", component: admin },
    { path: "/admin/edit/:id", component: admin },
    { path: "/admin/:section", component: admin },
    { path: "/admin/:section/:action", component: admin },
    { path: "/admin/:section/:action/:id", component: admin },
    {
      path: "/auth/callback",
      component: {
        template: '<div class="auth-component"></div>'
      }
    },

    // { path: '/module/:compname' },
    { path: "*", redirect: "/" }
  ]
});
export default router;
