
// Get signalr.d.ts.ts from https://github.com/borisyankov/DefinitelyTyped (or delete the reference)
// <reference path="signalr.d.ts" />
// <reference path="jquery.d.ts" />
//AUTOGENERATED DO NOT EDIT THIS FILE 2018-08-29 12:15:48
////////////////////
// available hubs //
////////////////////
//#region available hubs
//JS header:


declare var $: any;
import fetchival from 'fetchival';


export class Api {


  /** * The hub implemented by VueDotnetSSR.Controllers.HomeController    */
  public home: HomeController;


  /** * The hub implemented by bVue.code.bHub    */
  public bHub: bHub;


  /** * The hub implemented by bVue.code.SlidesController    */
  public slides: SlidesController;


  /** * The hub implemented by bVue.code.controllers.FileController    */
  public file: FileController;


  public connection: any;
  public url: string;
  public cookie: string;
  public connected: boolean;

  constructor(url: string, cookie: string) {

    this.url = url; this.cookie = cookie;
    this.connected = true;

    this.home = new HomeController(this.url, this.cookie);

    this.bHub = new bHub(this.url, this.cookie);

    this.slides = new SlidesController(this.url, this.cookie);

    this.file = new FileController(this.url, this.cookie);


  }
}
//#endregion available hubs

///////////////////////
// Service Contracts //
///////////////////////
//#region service contracts



export class HomeController {
  url: string;
  cookie: string;
  constructor(url: string, cookie: string) {

    this.url = url + "/home/"; this.cookie = cookie;
  }
  geth() { return { 'Accept': 'application/json', token: this.cookie }; }



  index(): Promise<IActionResult> {


    return fetchival(this.url + 'Index', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({

    });


  }


  login(): Promise<IActionResult> {


    return fetchival(this.url + 'Login', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({

    });


  }

}



//#endregion HomeController hub




export class bHub {
  url: string;
  cookie: string;
  constructor(url: string, cookie: string) {

    this.url = url + "/bHub/"; this.cookie = cookie;
  }
  geth() { return { 'Accept': 'application/json', token: this.cookie }; }



  contactMe(text: string): Promise<ValueTask<Boolean>> {


    return fetchival(this.url + 'ContactMe', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      text: text,


    });


  }


  getPostsAuth(search: string, orderby: string, page: number, pagesize: number): Promise<Post[]> {


    return fetchival(this.url + 'GetPostsAuth', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,


    });


  }


  getPosts(search: string, orderby: string, page: number, pagesize: number): Promise<Post[]> {


    return fetchival(this.url + 'GetPosts', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,


    });


  }


  insertPost(p: Post): Promise<number> {


    return fetchival(this.url + 'InsertPost', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  updatePost(p: Post): Promise<boolean> {


    return fetchival(this.url + 'UpdatePost', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  deletePost(id: number): Promise<number> {


    return fetchival(this.url + 'DeletePost', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      id: id,


    });


  }


  getCategories(search: string, orderby: string, page: number, pagesize: number): Promise<Category[]> {


    return fetchival(this.url + 'GetCategories', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,


    });


  }


  insertCategory(p: Category): Promise<number> {


    return fetchival(this.url + 'InsertCategory', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  updateCategory(p: Category): Promise<boolean> {


    return fetchival(this.url + 'UpdateCategory', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  deleteCategory(id: number): Promise<number> {


    return fetchival(this.url + 'DeleteCategory', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      id: id,


    });


  }


  getTags(search: string, orderby: string, page: number, pagesize: number): Promise<Tag[]> {


    return fetchival(this.url + 'GetTags', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,


    });


  }


  insertTag(p: Tag): Promise<number> {


    return fetchival(this.url + 'InsertTag', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  updateTag(p: Tag): Promise<boolean> {


    return fetchival(this.url + 'UpdateTag', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  deleteTag(id: number): Promise<number> {


    return fetchival(this.url + 'DeleteTag', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      id: id,


    });


  }


  insertComment(p: Comment): Promise<number> {


    return fetchival(this.url + 'InsertComment', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  updateComment(p: Comment): Promise<boolean> {


    return fetchival(this.url + 'UpdateComment', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  deleteComment(id: number): Promise<number> {


    return fetchival(this.url + 'DeleteComment', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      id: id,


    });


  }


  getComments(postID: number, search: string, orderby: string, page: number, pagesize: number): Promise<Comment[]> {


    return fetchival(this.url + 'GetComments', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      postID: postID,

      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,


    });


  }


  getCommentsAuth(postID: number, search: string, orderby: string, page: number, pagesize: number, auth: boolean): Promise<Comment[]> {


    return fetchival(this.url + 'GetCommentsAuth', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      postID: postID,

      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,

      auth: auth,


    });


  }


  createhash(password: string): Promise<string> {


    return fetchival(this.url + 'createhash', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      password: password,


    });


  }


  addUser(u: User): Promise<boolean> {


    return fetchival(this.url + 'AddUser', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      u,

    );


  }


  delUser(u: User): Promise<boolean> {


    return fetchival(this.url + 'DelUser', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      u,

    );


  }


  userExists(u: User): Promise<boolean> {


    return fetchival(this.url + 'UserExists', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      u: u,


    });


  }


  userValid(u: User): Promise<boolean> {


    return fetchival(this.url + 'UserValid', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      u,

    );


  }


  getUsers(search: string, orderby: string, page: number, pagesize: number): Promise<User[]> {


    return fetchival(this.url + 'GetUsers', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,


    });


  }


  returnSome(): Promise<string> {


    return fetchival(this.url + 'ReturnSome', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({

    });


  }

}



//#endregion bHub hub




export class SlidesController {
  url: string;
  cookie: string;
  constructor(url: string, cookie: string) {

    this.url = url + "/slides/"; this.cookie = cookie;
  }
  geth() { return { 'Accept': 'application/json', token: this.cookie }; }



  getSlides(search: string, orderby: string, page: number, pagesize: number): Promise<Slides[]> {


    return fetchival(this.url + 'GetSlides', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      search: search,

      orderby: orderby,

      page: page,

      pagesize: pagesize,


    });


  }


  get_UserName(): Promise<string> {


    return fetchival(this.url + 'get_UserName', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({

    });


  }


  insertSlides(p: Slides): Promise<number> {


    return fetchival(this.url + 'InsertSlides', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  updateSlides(p: Slides): Promise<boolean> {


    return fetchival(this.url + 'UpdateSlides', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      p,

    );


  }


  deleteSlides(ID: string): Promise<boolean> {


    return fetchival(this.url + 'DeleteSlides', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      ID: ID,


    });


  }

}



//#endregion SlidesController hub




export class FileController {
  url: string;
  cookie: string;
  constructor(url: string, cookie: string) {

    this.url = url + "/file/"; this.cookie = cookie;
  }
  geth() { return { 'Accept': 'application/json', token: this.cookie }; }



  uploadFiles2(files: IFormFile[]): Promise<string[]> {


    return fetchival(this.url + 'UploadFiles2', {
      headers: this.geth(),
      // mode:'no-cors'

    }).post(
      files,

    );


  }


  fileSearch(search: string, page: number, pageSize: number): Promise<BFileInfo[]> {


    return fetchival(this.url + 'FileSearch', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      search: search,

      page: page,

      pageSize: pageSize,


    });


  }


  download2(ID: string): Promise<IActionResult> {


    return fetchival(this.url + 'Download2', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      ID: ID,


    });


  }


  fileDelete(ID: string): Promise<boolean> {


    return fetchival(this.url + 'FileDelete', {
      headers: this.geth(),
      // mode:'no-cors'

    }).get({
      ID: ID,


    });


  }

}



//#endregion FileController hub


//#endregion service contracts



////////////////////
// Data Contracts //
////////////////////
//#region data contracts



/**
  * Data contract for Microsoft.AspNetCore.Mvc.IActionResult
  */
export class IActionResult {


}



/**
  * Data contract for System.Threading.Tasks.ValueTask`1[[System.Boolean, System.Private.CoreLib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]]
  */
export class ValueTask<Boolean> {

  IsCompleted: boolean;
  IsCompletedSuccessfully: boolean;
  IsFaulted: boolean;
  IsCanceled: boolean;
  Result: boolean;

}



/**
  * Data contract for Microsoft.AspNetCore.Http.IFormFile
  */
export class IFormFile {

  ContentType: string;
  ContentDisposition: string;
  Headers: IHeaderDictionary;
  Length: number;
  Name: string;
  FileName: string;

}



/**
  * Data contract for bVue.code.BEntity
  */
export class BEntity {

  ID: number;
  DateCreated!: Date;
  DateChanged: Date;
  Lang: Language;
  Count: number;
  UserCreated: string;

}



/**
  * Data contract for Microsoft.AspNetCore.Http.IHeaderDictionary
  */
export class IHeaderDictionary {

  Item: StringValues;
  ContentLength: number;

}



/**
  * Data contract for bVue.code.Language
  */
export class Language {


  en: 1;

  cs: 2;

  de: 3;

  hu: 4;

  nl: 5;

  es: 6;

}



/**
  * Data contract for System.Decimal
  */




/**
  * Data contract for Microsoft.Extensions.Primitives.StringValues
  */
export class StringValues {

  Count: number;
  Item: string;

}



/**
  * Data contract for bVue.code.Post
  */
export class Post extends BEntity {

  Title: string;
  Content: string;
  Blog: Blog;
  Categories: Category[];
  Tags: Tag[];
  Comments: Comment[];
  Published: boolean;
  Rating: number;
  Slug: string;
  FileInfos: BFileInfo[];

}



/**
  * Data contract for bVue.code.Category
  */
export class Category extends BEntity {

  Name: string;
  Posts: Post[];
  PostCount: number;

}



/**
  * Data contract for bVue.code.Tag
  */
export class Tag extends Category {


}



/**
  * Data contract for bVue.code.Comment
  */
export class Comment extends BEntity {

  AuthProvider: string;
  AuthMail: string;
  AuthWeb: string;
  AuthImg: string;
  AuthName: string;
  AuthUserName: string;
  AuthID: string;
  AuthIP: string;
  Text: string;
  Post: Post;
  Approved: boolean;
  ip: IPInfo;

}



/**
  * Data contract for bVue.code.User
  */
export class User extends BEntity {

  UserName: string;
  Role: Role;
  Password: string;
  Email: string;
  IsAdmin: boolean;
  Blog: Blog[];

}



/**
  * Data contract for bVue.code.Slides
  */
export class Slides extends BEntity {

  Name: string;
  Content: string;
  Public: boolean;
  Slug: string;

}



/**
  * Data contract for bVue.code.BFileInfo
  */
export class BFileInfo extends BEntity {

  size: number;
  sizeSh: string;
  FileName: string;
  FileID: string;
  MimeType: string;
  ext: string;
  hash: string;

}



/**
  * Data contract for bVue.code.Blog
  */
export class Blog extends BEntity {

  Name: string;
  Posts: Post[];

}



/**
  * Data contract for bVue.code.IPInfo
  */
export class IPInfo extends BEntity {

  ip: string;
  continent: string;
  countryProvince: string;
  country: string;
  countryCode2: string;
  countryFlag: string;
  city: string;
  isp: string;
  organisation: string;
  latit: number;
  longt: number;
  currName: string;
  currCode: string;

}



/**
  * Data contract for bVue.code.Role
  */
export class Role extends BEntity {

  Name!: string;

}

export default Api;
//#endregion data contracts


