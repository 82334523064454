<template>
  <div v-if="comment!=null">
   <div class="row">
                    <div class="col-8 col-sm-9">
                      <div style="float:left" >
                        <b> {{ comment.AuthName }}  </b>|&nbsp;  {{ comment.DateCreated | dtformat }} 
                      </div>
                  
                    </div>
                   
             </div>
             <div class="row">
                  <div class="col" v-html="comment.Text">
                      
                    </div>
             </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch, d } from "@/ext1";

@Component({
  name: "commentunit"
})
export default class commentun extends Vue {
  @Prop() comment: d.Comment;

  public somemethod(newSelected) {
    console.log("enter your code");
    // this.comment.Text
  }
}
</script>
<!--<style lang="less" >
</style>-->