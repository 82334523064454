<template>
  <div>
    <div class="form-group col-10">
      <div class="alert alert-success" :class="{'d-none':!inserted}" role="alert">
        <strong>Success!</strong> Comment inserted, Thank you.
      </div>
    </div>
    <div class="form-group">
      <label for="example-text-input" >Comments</label>
      <!--div class="col-10">
      <label for="example-text-input" class="col-2 col-form-label">UserName</label>
      </div-->
    </div>
    <div id="comment" ref="cmt" class="form-row">
      <div class="col">
        <input
          type="text"
          name="name"
          v-validate="{ required: true }"
          :class="{err: errors.has('name')}"
          v-model="comm.AuthName"
          class="form-control"
          placeholder="Name"
        >
      </div>
      <div class="col">
        <input
          type="text"
          v-model="comm.AuthMail"
          v-validate="{ required: true, email: true }"
          :class="{err: errors.has('email')}"
          name="email"
          class="form-control"
          placeholder="email"
        >
      </div>
    </div>

    <div class="form-row">
      <div class="col-12">
        <label for="exampleTextarea">Content</label>
        <textarea
          class="form-control"
          placeholder="Content"
          :class="{err: errors.has('content')}"
          v-validate="{ required: true }"
          name="content"
          v-model="comm.Text"
          id="exampleTextarea"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="row pull-right">
      <div class="col-md-6"></div>
      <div class="col-md-2">
        <label for="lblnum">{{ formula }}</label>
      </div>
      <div class="col-md-2">
        <input
          type="text"
          :class="{err: errors.has('num')}"
          name="num"
          @keyup="validate"
          v-validate.disable="'required|truthy'"
          v-model="num"
          class="form-control form-control-sm"
          id="num"
        >
      </div>
      <div class="col-md-2">
        <button type="submit" @click="addcomment()" class="btn btn-primary pull-right">Send</button>
      </div>
    </div>
    <div v-if="comments!=null">
      <br>
      <br>
      <cu v-for="(item) in comments" :key="item.ID" :comment="item"/>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch, d } from "@/ext1";
import store from "@/system/store";
//var { getters, commit } = getHelper(store)

var VVue = import("vue");
var VueAuthenticate = import("vue-authenticate");
import { Validator } from "vee-validate";
import cu from "./comment-unit.vue";
import {  Response } from "redaxios";

//VVue.use(require('vue-resource'));
//store.state.vars
var vars = store.state.vars; // store.getters("vars");
var url =
  vars.servurl.indexOf("localhost") > 0
    ? "http://localhost:5000"
    : "http://atrock.tk:4000";

// VVue.use(VueAuthenticate, {
//   baseUrl: url, // Your API domain

//   providers: {
//     github: {
//       clientId: 'b2731298da7f42223027',
//       redirectUri: window.location.origin+'/auth/callback' // Your client app URL
//     },
//     google: {
//       clientId: '498179100040-b4o00tggn9uab7arv7psit8bue5uju3h.apps.googleusercontent.com',
//       redirectUri: window.location.origin+'/auth/callback' // Your client app URL
//     },
// 	 facebook: {
//       clientId: '414196485631387',
//       redirectUri: window.location.origin+'/auth/callback' // Your client app URL
//     },
// 	 twitter: {
//       clientId: 'n8PbwG5M4r8RByfOagGz48uVi',
//       redirectUri: window.location.origin+'/auth/callback' // Your client app URL
//     }

//   }
// });

var multiselect = import("vue-multiselect").default;

@Component({
  components: { multiselect: multiselect, cu } //multiselect:Multiselect
})
export default class comment extends Vue {
  @Prop() postID: number;
  name = "comment";
  comm: d.Comment = new d.Comment();
  inserted = false;
  opt = ["facebook", "google", "twitter", "github"];
  selprovider = "facebook";
  response = {};
  $auth: any;
  num = "";
  valid = false;
  comments: d.Comment[] = null;
  makenewformula = false;
  get formula() {
    if (!this.makenewformula) {
      return "5+" + this.randomfromInterval(1, 10).toString() + "=";
    }
    this.makenewformula = false;
    return "5+" + this.randomfromInterval(1, 10).toString() + "=";
  }

  created() {
    Validator.extend("truthy", {
      getMessage: field => "The " + field + " value is not truthy.",
      validate: value => {
        return this.validnum();
      }
    });
    if (this.postID > 0) {
      this.db.bHub
        .getComments(this.postID, "", "", 1, 100)
        .then((cm: d.Comment[]) => {
          this.comments = cm;
        });
    }
  }
  mounted() {
    //console.log(this.$route.fullPath);
    if (this.$route.fullPath.indexOf("#comment") > 0) {
      //console.log("scrolling");
      (this.$refs["cmt"] as HTMLDivElement).scrollIntoView();
    }
  }
  async validate() {
    await this.$validator.validateAll().then(result => {
      if (result) {
        if (this.validnum()) {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }
    });
    return this.valid;
  }
  validnum() {
    var r = eval(this.formula.replace("=", ""));
    if (r == this.num) return true;

    return false;
  }
  public async addcomment() {
    this.validate();
    if (this.valid) {
      this.comm.Post = new d.Post();
      this.comm.AuthProvider = "email"; //this.selprovider;
      //this.comm.AuthName.AuthMail = this.e
      this.comm.DateCreated = new Date();
      this.comm.Post.ID = this.postID;
      await this.setipinfo(this.comm);
      try {
        this.log("add comment");
        this.db.bHub.insertComment(this.comm).then(() => {
          this.inserted = true;
          this.comm = new d.Comment();
          this.num = "";
          this.makenewformula = true;
          this.$forceUpdate();
          setTimeout(() => {
            this.inserted = false;
          }, 4000);
        });
        //old code
        // if (
        //   this.vars.oauth != null &&
        //   this.vars.oauth.provider == this.selprovider
        // ) {
        //   // this.fillResponse(this.selprovider, this.vars.oauth.data);
        //   this.inserted = true;
        //   this.log("fill comment");
        //   //add comment

        //   //and clean text
        //   this.comm.Text = "";
        // } else {
        //   //function as parameter
        //   this.authenticate(this.selprovider, () => this.addcomment);
        //   this.log("auth");
        // }
      } catch (Error) {}
    }
  }
  //function callback as parameter
  async setipinfo(c: d.Comment): Promise<boolean> {
    c.ip = new d.IPInfo();
    return this.axios
      .get(
        "https://api.ipgeolocation.io/ipgeo?apiKey=2f80c72c8627446280b4520675dda9ad"
      )
      .then((val: Response) => {
        var d = val.data;
        var cc = c.ip;
        cc.ip = d.ip;
        cc.continent = d.continent_name;
        cc.longt = d.longitude;
        cc.currName = d.currency.name;
        cc.currCode = d.currency.code;
        cc.DateCreated = new Date();
        cc.countryProvince = d.state_prov;
        cc.country = d.country_name;
        cc.countryCode2 = d.country_code2;
        cc.countryFlag = d.country_flag;
        cc.city = d.city;
        cc.isp = d.isp;
        cc.organisation = d.organization;
        cc.latit = d.latitude;
        return true;
      })
      .catch(err => {
        return false;
      });
  }
  setvaribales() {
    this.vars.oauth = { provider: this.selprovider, data: this.response };
    this.setvars(this.vars);
  }
  randomfromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  fillResponse(provider, response) {
    if (provider != "" && response != null) {
      //this.response = response;
      this.comm.AuthID = this.comm.AuthUserName = this.comm.AuthMail = this.comm.AuthName = this.comm.AuthImg = null;
      this.log(provider);
      if (provider == "facebook") {
        this.comm.AuthID = response.id;
        this.comm.AuthName = response.name;
        this.log("fb");
      }
      if (provider == "google") {
        this.comm.AuthID = response.sub;
        this.comm.AuthMail = response.email;
        this.comm.AuthName = response.name;
        this.comm.AuthImg = response.picture;
      }
      if (provider == "github") {
        this.comm.AuthID = response.id;
        this.comm.AuthUserName = response.login;
        this.comm.AuthMail = response.email;
        this.comm.AuthName = response.name;
        this.comm.AuthImg = response.avatar_url;
      }
      if (provider == "twitter") {
        this.comm.AuthID = response.profile.id_str;
        this.comm.AuthMail = response.profile.email;
        this.comm.AuthName = response.profile.screen_name;
        this.comm.AuthUserName = response.profile.screen_name;
        this.comm.AuthImg = response.profile.profile_image_url;
      }
      //  if(provider!='twitter')
      //   this.vars.oauth = { provider:this.optm, data:response.data };

      // else {
    }
  }
  authenticate(provider, donecallback?: () => void) {
    //  this.$auth.logout();
    this.response = null;
    this.$auth.authenticate(provider).then(authres => {
      console.log("authcalled");
      console.log(authres);
      if (provider === "github") {
        this.axios
          .get("https://api.github.com/user", {
            params: { access_token: this.$auth.getToken() }
          })
          .then(response => {
            this.response = response.data;
            this.setvaribales();
            this.fillResponse(
              provider,
              Object.assign({}, authres.body.la, response.data)
            );
            donecallback();
          });
      } else if (provider === "facebook") {
        this.axios
          .get("https://graph.facebook.com/v2.5/me", {
            params: { access_token: this.$auth.getToken() }
          })
          .then(response => {
            this.response = response.data;
            this.setvaribales();
            this.fillResponse(
              provider,
              Object.assign({}, authres.body.la, response.data)
            );
            donecallback();
          });
      } else if (provider === "google") {
        this.axios
          .get("https://www.googleapis.com/plus/v1/people/me/openIdConnect", {
            params: { access_token: this.$auth.getToken() }
          })
          .then(response => {
            this.response = response.data;
            this.fillResponse(
              provider,
              Object.assign({}, authres.body.la, response.data)
            );
            this.setvaribales();
            donecallback();
          });
      } else if (provider === "twitter") {
        this.response = authres.body; // authresponse.body.profile
        this.fillResponse(
          provider,
          Object.assign({}, authres.body.la, this.response)
        );
        this.setvaribales();
        donecallback();
      }
      // Execute application logic after successful social authentication
    });
  }
}
</script>
<!--<style lang="less" >
</style>-->