<template>
  <component :is="master"></component>
</template>
<script lang="ts">
/** IE10 and IE11 requires the following for NgClass support on SVG elements */
//import 'classlist.js';  // Run `npm install --save classlist.js`.


import * as VueRouter from "vue-router";
const admin = () => import("./views/admin.vue");
//import  admin from "./views/admin.vue";
//import master from "./views/master.vue";
var master = () => import("./views/mdmaster.vue");
//const slides = () => import("./views/slides.vue");
type tmaster = "master" | "admin" | "slides";

import {
  Component,
  Inject,
  Model,
  Prop,
  Vue,
  Watch,
} from "vue-property-decorator";

@Component({
  components: { admin, master  }//,slides

  //,
  //  beforeRouteEnter(to, from, next) {
  //     console.log("beforeenter11");
  //  }
}) //,separatets, about , Bootstrap
export default class App extends Vue {
  name = "App";
  //cr = false;
  //@Prop({ default: 'master', required: false })
  master: tmaster = "master";
  currurl = "/";

  created() {
    this.initview();
    //set document title per master
    document.title = this.doctitle;
    this.setmeta();
  }
  initview() {
    this.currurl = window.location.pathname.toLowerCase();
    var m = this.master;
    // console.log(this.$route.path);
    var slides: boolean = false;
    var admin: boolean = false;
    if (this.hasurl(this.currurl, "/slides/")) {
      m = "slides";
      slides = true;
    } else if (this.hasurl(this.currurl, "/admin/")) {
      m = "admin";
      admin = true;
    }
    if (!admin && !slides) {
      m = "master";
    } //only change master if you need to, causes refresh
    if (this.master != m) {
      this.master = m;
    }
    return this.master;
  }

  hasurl(baseurl: string, url: string): boolean {
    return baseurl.indexOf(url.toLowerCase()) > -1;
  }

  @Watch("$route", { immediate: true })
  watchpath(this:any, to?: any, from?: any) {
    this.initview();
    document.title = this.doctitle;
  }

  setmeta() {
    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector("head title");
    var m = this.meta();
    if (descEl) descEl.setAttribute("content", m[0]["content"]);
    // titleEl.textContent = items[0]['meta-title'];
  }

  meta() {
    return [
      { name: "description", content: "rocks info and more" },
      { name: "twitter:title", content: "Content Title" },
    ];
  }

  get doctitle(): string {
    return window.location.host;
  }
}
</script>
