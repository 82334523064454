<template>
  <div ref="scroll">
    <div class="clearfix" v-for="post in posts" :key="post.ID">
      <post :shortview="true" :post="post"></post>
    </div>
    <div v-if="posts!=[]">
      <nav class=" text-center">
        <router-link v-if="pagenr>1" class="btn btn-default" :to="prevlink">Newer</router-link>
        <a v-else class="btn btn-default disabled" href="#">Newer</a>
        <router-link v-if="pagenr<pagecount" class="btn btn-default" :to="nextlink">Older</router-link>
        <a v-else class="btn btn-default disabled" href="#">Older</a>
      </nav>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Watch, d, Vue } from "@/ext1";
//import { RouterOptions, Location, RouteConfig, Route } from "vue-router";

import post from "./mdpost.vue";
//import { cPostList } from "@/system/store";

@Component({
  name: "home",
  components: {
    //   multiselect:multiselect,
    post
  }
})
export default class home extends Vue {
  id = "home";
  posts: d.Post[] = [];
  pagenr = 1;
  pagesize = 3;
  pagecount = 1;
  search = "";
  catsearch = "";
  tagsearch = "";

  get nextlink() {
    if (this.catsearch.length > 0) {
      return "/category/" + this.catsearch + "/page/" + (this.pagenr + 1);
    }
    if (this.tagsearch.length > 0) {
      return "/tag/" + this.tagsearch + "/page/" + (this.pagenr + 1);
    }

    return this.search.length > 0
      ? "/search/" + this.search + "/page/" + (this.pagenr + 1)
      : "/page/" + (this.pagenr + 1);
  }
  get prevlink() {
    if (this.catsearch.length > 0) {
      return "/category/" + this.catsearch + "/page/" + (this.pagenr - 1);
    }
    if (this.tagsearch.length > 0) {
      return "/tag/" + this.tagsearch + "/page/" + (this.pagenr - 1);
    }

    return this.search.length > 0
      ? "/search/" + this.search + "/page/" + (this.pagenr - 1)
      : "/page/" + (this.pagenr - 1);
  }
  get finalsearch() {
    if (this.catsearch.length > 0) {
      return "c:" + this.catsearch;
    }
    if (this.search.length > 0) {
      return this.search;
    }
    if (this.tagsearch.length > 0) {
      return "t:" + this.tagsearch;
    }
    return "";
  }

  created() {
    this.setroutevals();
    this.getData();
  }
  // mounted() {
  //   //this.log(this.$store.state.vars.lang);
  // }
  updated() {
    this.scrooll();
  }

  scrooll() {
    // if (this.$route.fullPath.indexOf("#") < 0) {
    //   if (this.$route.params["nr"] != null) {
    //     (this.$refs["scroll"] as HTMLDivElement).scrollIntoView();
    //     //  this.log("s-top");
    //   } else {
        this.scrolltop();
        // this.log("top");
    //   }
    // }
  }

  setroutevals() {
    if (this.$route.params["nr"] != null) {
      this.pagenr = Number(this.$route.params["nr"]);
    }
    if (this.$route.params["term"] != null) {
      this.search = this.$route.params["term"]+this.gethash();
      this.catsearch = this.tagsearch = "";
    }
    if (this.$route.params["cname"] != null) {
      this.catsearch = this.$route.params["cname"]+this.gethash();
      this.search = this.tagsearch = "";
    }
    if (this.$route.params["tname"] != null) {
      this.tagsearch = this.$route.params["tname"]+this.gethash();
      this.search = this.catsearch = "";
    }
  }

  gethash()
  {
    return this.$route.hash.indexOf("/")>0?
     this.$route.hash.split("/")[0]
    :this.$route.hash;
  }

  getData() {
    if (
      //this.db.connected &&
      this.posts.length<1
    ) {
      this.db.bHub
        .getPosts(this.finalsearch, "", this.pagenr, this.pagesize)
        .then((res: d.Post[]) => {
          this.posts = res; //
           // var p = new cPostList();
          //  p.path = this.$route.path;
          //    p.cposts = this.posts;

          if (res.length > 0) this.pagecount = res[0].Count / this.pagesize;
          // p.count = this.pagecount;
          if(res.length>0)
            { this.setpostlist(res); }

          //var d = new Date().toLocaleString(.date.
        })
        .catch((reason: any) => {
          console.log(reason);
        });
    }
    // (this.$refs["scroll"] as HTMLDivElement).scrollIntoView();
  } 

  delpost(ID: number) {
    //console.log("deleting"+ID);
    if (confirm("Delete?")) {
      this.db.bHub.deletePost(ID).then(() => {
        this.getData();
        // console.log("getin data");
      });
    }
  }

  // @Watch("connected")
  // watchthis(neval, oldval) {
  //   // this.$route.path:string , newVal?:any, oldVal?:any
  //   //console.log('watch init');
  //   console.log(this.$route.path + " >watch");
  //   if (this.connected) {
  //     if (this.posts == null) {
  //       this.getData();
  //     }
  //   }
  // }
}
</script>
<style lang="less" scoped>
/* Custom default button */

.btn.outline-white {
  background: transparent;
  border: 1px solid #fff;
  box-shadow: none;
  color: #fff;
  text-decoration: none;
}
.btn.outline-white:hover {
  background: #fff;
  border-color: #fff;
  color: #383838;
  top: 0;
  text-decoration: none;
}

.btn.outline-grey {
  background: transparent;
  border: 1px solid #fff;
  box-shadow: none;
  color: #fff;
  text-decoration: none;
  height: 15px;
}
.btn.outline-grey:hover {
  background: #808080;
  border-color: #fff;
  color: #383838;
  top: 0;
  text-decoration: none;
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: 0.05rem solid #fff;
}
.blog-pagination {
  margin-bottom: 1rem;
}
.blog-pagination > .btn {
  border-radius: 2rem;
}
</style>