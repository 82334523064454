//import { Component, create, getHelper, Store } from '../ext'
import Vue from 'vue'

import { StorageService } from './localstorage'
import * as d from '../components/domain'
import config, { valconfig } from './validator'
// export {
//   d
// }
//export * as dd  from '../components/domain'
//import { d } from "../ext1";
import Vuex from 'vuex'
Vue.use(Vuex);
interface Oauth {
  data: object,
  provider: string
}
export class cPostList {
  path: string;
  cposts: d.Post[]
  count: number
}
export interface storeData {
  count: number, signedin: boolean, lang: string,
  custid: number,
  location: string, token: string, servurl: string, dateformat: string//, db:d.SgnRCloud
  oauth: Oauth,
  currpost: d.Post
  cpostlist: d.Post[],
  valconfig: valconfig
}
declare let window: Window;
var host = window.document.location.port == '8080' ? 'http://localhost:5000' : window.location.origin;
//will be in local storage
const dstate: storeData = {
  count: 0, signedin:false, token: '', lang: 'de',
  custid: 0, location: 'AT',
  servurl: host, dateformat: 'd.M.yyyy'
  , oauth: null
  , currpost: null
  , cpostlist: null
  , valconfig: config
};


const storage = new StorageService();

storage.setItemInit(storage.C_ENV_KEY, dstate);


const storeData: storeData = JSON.parse(storage.getItem(storage.C_ENV_KEY));

//most simplyfied no vuex
// var storee = {
//                db: new d.SgnRCloud(dstate.servurl, storeData.token),
//                vars:storeData, 
//                setvars:(s:storeData)=>{storee.vars = s; storage.setItem(storage.C_ENV_KEY, s)},
//                setdb:(s:d.SgnRCloud)=>{storee.db = s;}
//              }

//playing around with vuex
export interface State {
  db: d.Api,
  vars: storeData,

}
const statee: State = {
  db: new d.Api(dstate.servurl, storeData.token),
  vars: storeData

}
const store = new Vuex.Store<State>({

  state: statee,
  mutations: {
    setvars(state, s: storeData) {
      state.vars = s; storage.setItem(storage.C_ENV_KEY, s)
    },
    setdb(state, s: d.Api) { state.db = s; },
    setpost(state, p: d.Post) {
      state.vars.currpost = p; //storage.setItem(storage.C_ENV_KEY, state.vars);
    }
    , setpostlist(state, p: d.Post[]) {
      state.vars.cpostlist = p;//storage.setItem(storage.C_ENV_KEY, state.vars);
    }
  },
  getters: {
    isAuth: state => () => {
      return state.vars.signedin && storage.isValid();
    }
  }
})

//  store.subscribe(s => {
//    if(s.type=='setvars')
//    {console.log('subscribed');}
//  })    
store.subscribe((mutate, statee) => {
  if (mutate.type == 'setvars') { console.log('subscribed muttate'); 
  // if(!statee.vars.signedin && (mutate.payload as State).vars.signedin)
  // { 
  //  // storage.setInitTime();
  // }
}
})
//cmpdStore.db = new d.SgnRCloud(state.servurl, state.token);
//END COMpound store

//how to subscribe
// store.subscribe(s => {
//   if (s.type == 'increment') console.log('sumbsicribed increment is' + JSON.stringify(s.payload))
//   // else console.log(s.payload) //s
//   //console.log(store.state)
// })//
export default store