<template>
  <div>
    <div ref="pscroll"></div>
    <article v-if='dpost != null' class="clearfix">
      <div class="images" v-viewer.static="{ toolbar: false }">
        <div class="post-date">
          <div class="row justify-content-between">
            <div class="col-8 col-auto me-auto">
              {{ dpost.DateCreated | dtformat }} | <a class="author" href=""> Jack </a>
            </div>
            <div class="col-auto">
              <span class="cmt"> <router-link :to="'/post/' + dpost.ID + '#comment'" title="comment">
                  <div v-if="dpost.Comments != null">{{ dpost.Comments.length }}</div>
                  <i class="far fa-comments fa-2" aria-hidden="true"></i>
                </router-link> </span>
            </div>
          </div>

        </div>
        <h3> <router-link @click.native="setpost(dpost)" :to="'/post/' + dpost.ID"> {{ dpost.Title }}</router-link></h3>
        <p>
        <div class="post" v-if="dpost.Content != null">

          <div v-html="content"></div>
        </div>
        </br>
        <div v-if="shortview" class="blog-pagination text-center">
          <router-link :to="'/post/' + dpost.ID" class="">. . .</router-link>
        </div>
        <button v-if="!shortview" class="btn btn-primary pull-right ripple-surface" @click='back'> Back </button>

        </p>


        <PostFooter v-if="dpost != null" :post="dpost" v-on:delete='back'></PostFooter>
      </div>
    </article>
    <comment v-if="dpost != null && !shortview" :postID="dpost.ID" />

  </div>
</template>
<script lang="ts">
import { Component, Prop, Watch, Vue, d } from "@/ext1";
import  MarkdownIt from "markdown-it";
import markdownItVideo, * as MarkdownItVid from "markdown-it-video";
import hljs from "@/system/imports/hljs";
import VueRouter from "vue-router";
import comment from "@/components/comment.vue";
//import DateTime from "typescript-dotnet-commonjs/System/Time/DateTime"
import PostFooter from "@/components/post-footermd.vue";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
//image viewer
Vue.use(Viewer);

@Component({
  name: "postmd",
  components: {
    // multiselect:multiselect,
    //  calendar,
    comment,
    PostFooter, // VueRouter //,Vue
  },
})
export default class post extends Vue {
  name = "post";
  @Prop({ default: null, required: false })
  post!: d.Post;
  @Prop({ default: false })
  shortview!: boolean;
 
  dpost: d.Post|null=null;
  pagenr = 1;
  pagesize = 3;
  pagecount = 1;
    beforeMount() {
           if (this.post) {
           
              this.dpost = this.post;
           }
        }

  md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return hljs.highlight(lang, str).value;
        } catch (__) { }
      }

      return ""; // use external default escaping
    },
  });
  get content(): string {
    if (this.dpost != null) {
      var result = this.md.render(
        this.$options.filters!.filepaths(this.dpost.Content)
      );
      if (this.shortview) {
        return result.substr(0, 250);
      }
      return result;
    }
    return "";
  }
  created() {
    if (!this.shortview) {
      this.md = this.md.use(markdownItVideo, { //use(package_name) is required
        youtube: { width: 640, height: 390 },
        vimeo: { width: 500, height: 281 },
        vine: { width: 600, height: 600, embed: 'simple' },
        prezi: { width: 550, height: 400 }
      })
    }

  }

  mounted() {
    //because of scrolling not using created there are no refs
    if (this.dpost == null) {
      if (this.vars.cpostlist != null && this.$route.params["pname"] != null) {
        var f: d.Post = this.vars.cpostlist.find(
          (e) => e.ID.toString() == this.$route.params["pname"]
        );
        if (f != undefined) {
          this.dpost = f;
          this.scroll();
        } else {
          this.getData();
        }
      } else {
        this.getData();
      }
    }
    var defaultRender =
      this.md.renderer.rules.link_open ||
      function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };

    this.md.renderer.rules.link_open = function (
      tokens,
      idx,
      options,
      env,
      self
    ) {
      // If you are sure other plugins can't add `target` - drop check below
      var aIndex = tokens[idx].attrIndex("target");

      if (aIndex < 0) {
        tokens[idx].attrPush(["target", "_blank"]); // add new attribute
      } else {
        tokens[idx]!.attrs[aIndex][1] = "_blank"; // replace value of existing attr
      }

      // pass token to default renderer.
      return defaultRender(tokens, idx, options, env, self);
    };
  }

  updated() {
    if (!this.shortview && this.$route.fullPath.indexOf("#") < 0) {
      this.scroll();
    }
  }

  scroll() {
    if (this.$route.fullPath.indexOf("#") < 0) {
      //(this.$refs["pscroll"] as HTMLDivElement).scrollIntoView();
      document.body.scrollTop = window.innerHeight / 2.4; // For Chrome, Safari and Opera
      document.documentElement.scrollTop = window.innerHeight / 2.4; // For IE and Firefox
    }
  }
  //  formatpost(p:d.Post)
  //  {
  //    p.Content = this.md.render(p.Content);
  //    return p;
  //  }

  getData() {
    if (this.$route.params["pname"] != null) {
      if (this.connected) {
        this.db.bHub
          .getPosts(this.$route.params["pname"], "", this.pagenr, this.pagesize)
          .then((res: d.Post[]) => {
            this.dpost = res[0]; // this.formatpost( res[0]);//
            if (res.length > 0) this.pagecount = res[0].Count / this.pagesize;
            this.scroll();
          })
          .catch((reason: any) => {
            console.log(reason);
          });
      }
    }
  }

  back() {
    //window.history.back();
    this.$router.go(-1);
  }

  @Watch("connected")
  watchthis(neval, oldval) {
    // this.$route.path:string , newVal?:any, oldVal?:any
    //console.log('watch init');
    console.log("pwatch" + this.db.connected + " " + this.$route.path);
    if (this.db.connected && this.post == null) {
      this.getData();
    }
  }

  //  @Watch("$route",{ immediate:true })
  watchpath(newVal?: any, oldVal?: any) {
    // this.$route.path:string , newVal?:any, oldVal?:any
    if (this.$route.path != undefined && this.post == null) this.getData();
  }
}
</script>
<style lang="less" scoped>
:deep(pre code) {
  color: white;
  overflow: auto;
}

@link-color: #b9b9b9;

.post :deep( a ){
  color: @link-color !important;
}

article :deep( a:hover) {
  color: whitesmoke !important;
}

:deep( a) {
  font-weight: bold !important;
  color: @link-color;

  .fa {
    display: inherit !important;
  }

  .fa-15 {
    font-size: 1.5em;
  }

  .fa-2 {
    font-size: 2em;
  }

  .fa-3 {
    font-size: 3em;
  }
}

.content .post-date {
  font-family: "Open Sans", helvetica, arial, sans-serif;
  color: #9f9f9f;
  font-weight: bold;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  position: relative;
}

article .post-date {
  margin-top: 1em;
}

.content .post-date {
  font-family: "Open Sans", helvetica, arial, sans-serif;
  color: #9f9f9f;
  font-weight: bold;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  position: relative;
}

article {
  border-bottom: 1px solid #ccc;
  margin-bottom: 1em;
  color: white;
  word-break: break-all;
}

article .post-date {
  margin-top: 1em;
}

article h3 :deep( a) {
  color: white;
  border: 0;
  /* text-align: center; */
}

/* Links */

a:focus,
a:hover {
  color: #fff !important;
}

:deep( .post table td),
:deep( .post table th) {
  padding: 6px 13px;

  border: 1px solid #8a8888;
}

:deep(.post td,
th ){
  display: table-cell;
  vertical-align: inherit;
}

:deep(.post table) {
  display: table;
  border-spacing: 2px;
}

:deep( .post img) {
  max-width: 100%;
}
</style>
