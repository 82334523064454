declare var require: any;
//import "es6-promise/auto";
import { Component, Vue } from "vue-property-decorator";
//import { RouterOptions, Location, RouteConfig, Route } from "vue-router";
import VueRouter from "vue-router";
declare var require: any;
//import Vue = require('vue') abc++k00012001oo
import router from "./system/router";
import App from "./app.vue";
import store from "./system/store";
import Vuex from "vuex";
import { createHead, HeadVuePlugin } from "@vueuse/head"

const head = createHead()
// needed for Vue 2
Vue.use(HeadVuePlugin, head)
Vue.use(head)
Vue.use(VueRouter);
Vue.config.devtools = true; //process.env.NODE_ENV === "development"; // true; //enable debug for build

let app: Vue =
  //window.app =
  new Vue({
    el: "#app",
    router: router,
    store: store,
    components: { App: App },
    render: h => h("App"),
    //validations:{},
    methods: {
      //  validations(){}
    }
  });

export { app, router, store }; //app
