import { Component, Inject, Model, Prop, Watch } from "vue-property-decorator";
export {
  Component,
  Inject,
  Model,
  Prop,
  Watch,
  Emit,
  Provide,
  Mixins
} from "vue-property-decorator";
//import Vue from 'vue'
import store, { storeData, State, cPostList } from "./system/store";
//import { mapMutations } from "vuex";
declare var require: any;
import VueRouter from "vue-router";
import axio from "redaxios";
import * as domain from "./components/domain";
import { format, parseISO, isValid } from 'date-fns'
export { domain, domain as d };
import * as b from "vue-property-decorator";
import fetchival from "fetchival";
b.Vue.use(VueRouter);
//import { State,Mutation, Action, Getter } from 'vuex-class'

//extend inferace for validations
declare module "vue/types/options" {
  interface ComponentOptions<V extends b.Vue> {
    validations?: {}; //; validations () {};
  }
}

//declare module 'vue/types/vue' {
// 3. Declare augmentation for Vue
//interface Vue {
//$myProperty: string
//}
//}
//declare module "Vue" {
// @Component({
//   methods: mapMutations(['setvars','setdb','setpost','setpostlist'])
// })
export class FetchvOptions {
  mode?: "cors" | "no-cors" | "*same-origin";
  cache?: "no-cache" | "default" | "reload" | "force-cache" | "only-if-cached";
  credentials?: "same-origin" | "include" | "same-origin" | "omit";
  headers?: object = {
    "Content-Type": "application/json; charset=utf-8"
    // "Content-Type": "application/x-www-form-urlencoded",
  };
  redirect?: "follow" | "manual" | "follow" | "error";
  referrer?: "no-referrer" | "no-referrer" | "client";
}
export interface FetchivalInstance {
  (url: string): FetchivalInstance;
  (url: string, options: FetchvOptions): FetchivalInstance; //{
  //this.greeting = message;
  //}
  get(data?: any): Promise<any>;
  post(data?: any): Promise<any>;
  //not using put, pach, delete
}
export class Vue extends b.Vue {
 
  $v: any;
  $store = store;

  get db() {
    return store.state.db;
  }
  get connected() {
    return store.state.db.connected;
  }
  get vars() {
    return store.state.vars;
  }
  get isAuth() {
    return store.getters.isAuth();
  }
  axios = axio;//because upload problems with fetch until resolved
  fetchv: FetchivalInstance = fetchival;
  //@Store setvars = commit('varsset')
  public log(val: String) {
    console.log(val);
  }
  public logErr(val: String) {
    console.log(val);
  }

  setpost(p: domain.Post): void {
    store.commit("setpost", p);
  }
  setvars(vars: storeData): void {
    store.commit("setvars", vars);
  }
  setdb(db: domain.Api): void {
    store.commit("setdb", db);
  }
  setpostlist(p: domain.Post[] | null) {
    store.commit("setpostlist", p);
  }
  scrolltop() {
    //initial load function
    document.body.scrollTop = 0; // For Chrome, Safari and Opera
    document.documentElement.scrollTop = 0; // For IE and Firefox
  }
}
//}

var v = new Vue();
//EXAMPLE OF DATETIME formatting - FILTER

Vue.filter("dtformat", function (val) {
// console.log(val); console.log(v.vars.dateformat);
  if (val != null && val != "" && isDate(val)) return format(new Date(val),v.vars.dateformat);
  return "";
});
Vue.filter("filepaths", function (val) {
  if (val != null && val != "")
    return val.replace(/\$\//g, v.vars.servurl + "/file/Download2?ID=$/");
  return "";
});

function isDate(dateStr) {
  return !isNaN(new Date(dateStr).getDate());
}

// declare module "Vue" {
// export class Vue extends V {

//    public log(val:String){
//      console.log(val);
//    }
//    public logError(val:String){
//      console.log(val);
//    }
// }}
